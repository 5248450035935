<template>
    <div>
        <el-dialog class="cus_dialog " title="巡访统计" :visible.sync="dialogshow" :close-on-click-modal="false"
            @close="closeDialog" width="80%" height="90%">

            <div style="text-align: right;">



                <el-date-picker v-model="searchForm.month" size="small" type="monthrange" range-separator="至"
                    start-placeholder="开始月份" value-format="yyyy-MM" end-placeholder="结束月份">
                </el-date-picker>



                <el-button icon="el-icon-search" @click="getDataList" style="margin-left:10px" size="small"
                    type="primary">搜索</el-button>




            </div>
            <div id="chart" style="height:500px"></div>
        </el-dialog>


    </div>
</template>

<script>

export default {
    props: ["baoxian"],
    components: {

    },
    data() {
        return {
            dialogshow: true,
            saveBtnShow: false,
            DataList: [],
            searchForm: {
				month: []
			},
        }
    },
    mounted() {

        this.getDataList()
    },
    methods: {
        getDataList() {
			let data = {
				month: this.searchForm.month,
				showloading: true
			}
			this.$http.post("/api/xunfang_tongji", data).then(res => {
				this.tableData = res.data
				this.initChart()
			})
		},
		initChart() {

			let xdata = this.tableData;

			let option = {
				xAxis: {
					type: 'category',
					data: xdata.map(e => { return e.platform }),
					axisLabel: {
						rotate: 30 // 设置标签旋转角度为 45 度
					}
				},
				grid:{
					bottom:100,
				},
				yAxis: {
					type: 'value'
				},
				tooltip: {
					formatter: function (params) {
						
						const value = params.value;
						return '平均巡访次数：' + value + "<br />巡访总次数：" + xdata[params.seriesIndex].n +
							"<br />巡访教师人数：" + xdata[params.seriesIndex].tch_count;
					}
				},
				series: [
					{
						data: xdata.map(e => {
							let value = parseInt(e.n / e.tch_count * 100) / 100;
							let data = { value: value, itemStyle: { color: '#409eff' } }
							return data
						}),
						type: 'bar',
						barWidth: 30,
						showBackground: true,
						backgroundStyle: {
							color: 'rgba(180, 180, 180, 0.2)'
						}
					}
				]
			};
			var yjChart = this.$echarts.init(document.getElementById('chart'));
			yjChart.setOption(option);

		},
        closeDialog() {

            this.$$parent(this, "dialogShow1", false)

        },
        



    }

}

</script>

<style scoped></style>