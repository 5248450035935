<template>
	<div class="cbox">
		<div class="headbar">
			<div style="float: right">

				<el-select v-model="searchForm.school_id" clearable placeholder="全部学校" size="small"
					style="width:200px;margin-left:10px">
					<el-option label="全部学校" value></el-option>
					<el-option :label="item.platform" v-for="(item, idx) in SchoolList" :key="idx"
						:value="item.id"></el-option>

				</el-select>

				

				<el-cascader ref="firstCascader" :options="TreeData" :props="defaultProps" :show-all-levels="true"
					v-model="searchForm.class_name" @change="getSelectType" clearable placeholder="请选择" filterable
					style="width: 250px;margin:0 10px" size="small">
				</el-cascader>


				<el-select v-model="searchForm.month" @change="changeDept" clearable placeholder="请选择月份" size="small"
					style="width:120px;margin-left:10px">

					<el-option :label="year + '-01'" :value="1"></el-option>
					<el-option :label="year + '-02'" :value="2"></el-option>
					<el-option :label="year + '-03'" :value="3"></el-option>
					<el-option :label="year + '-04'" :value="4"></el-option>
					<el-option :label="year + '-05'" :value="5"></el-option>
					<el-option :label="year + '-06'" :value="6"></el-option>
					<el-option :label="year + '-07'" :value="7"></el-option>
					<el-option :label="year + '-08'" :value="8"></el-option>
					<el-option :label="year + '-09'" :value="9"></el-option>
					<el-option :label="year + '-10'" :value="10"></el-option>
					<el-option :label="year + '-11'" :value="11"></el-option>
					<el-option :label="year + '-12'" :value="12"></el-option>

				</el-select>

				<el-input placeholder="关键字" v-model="searchForm.keyword" size="small" class="input-with-select"
					style="width:150px;margin-left: 10px" clearable></el-input>


				<el-button icon="el-icon-search" @click="getDataList" style="margin-left:10px" size="small"
					type="primary">搜索</el-button>
				<el-button v-if="TargetPmid" icon="el-icon-refresh" style="margin-left:10px" size="small"
					@click="tongbuData">同步数据</el-button>

					<el-button icon="el-icon-data-analysis" @click="dialogShow1=true" style="margin-left:10px" size="small"
					type="primary">统计表</el-button>

					<el-button icon="el-icon-download" @click="exportXls" style="margin-left:10px"
					size="small">导出表格</el-button>


			</div>
			<div style="padding-top: 14px">
				
				<div style=" width: 200px;  margin-top:-10px;">
					<div  style="width:40px;height:40px;background-color: #ebf5ff; border-radius: 50%;float: left;">
					<img :src="'/img/page.png'" style="width:60%;height:60%;margin-left: 20%;margin-top:20%" />
				</div>

				<div style="margin-left: 50px; color:#535353; font-size:20px !important">巡访记录</div>
				
				</div>

			</div>
		</div>
		<div class="bcontent">


			<div>
				<el-table :data="tableData" row-key="id" border stripe>
					<el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
					<el-table-column label="学校" prop="platform" min-width="150" align="center"></el-table-column>
					<el-table-column label="所属系部" prop="xibu_name" width="150" align="center"></el-table-column>
					<el-table-column label="所属班级" prop="class_name" width="150" align="center"></el-table-column>
					<el-table-column label="巡访人员" prop="tch_name" min-width="150" align="center"></el-table-column>
					<el-table-column label="联系方式" prop="tch_phone" width="150" align="center"></el-table-column>
					<el-table-column label="累计巡访次数" prop="n" width="150" align="center"></el-table-column>
					<el-table-column label="巡访时间" prop="xfmonth" width="150" align="center">
						<template slot-scope="scope">
							{{ scope.row.year + (scope.row.xfmonth ? '-' + (scope.row.xfmonth
								< 10 ? '0' + scope.row.xfmonth : scope.row.xfmonth) : '年') }} </template>

					</el-table-column>
					<el-table-column fixed="right" label="操作" width="100">
						<template slot-scope="scope">
							<el-button @click="viewInfo(scope.row)" type="text" size="small">查看</el-button>
						</template>
					</el-table-column>
				</el-table>

				<div style="text-align:right;padding-top: 10px;">
					<el-pagination background @current-change="handlePageChange" :current-page="page.current_page"
						:page-size="page.per_page" :total="page.count" layout="total, prev, pager, next"></el-pagination>
				</div>


			</div>

		</div>
		<xunfanginfo v-if="dialogShow" :xunfang="xunfang"></xunfanginfo>
		<xunfangtongji v-if="dialogShow1" :xunfang="xunfang"></xunfangtongji>

		

	</div>
</template>

<script>
import xunfanginfo from './com/xunfangInfo.vue'
import xunfangtongji from './com/xunfangtongji.vue'

export default {
	components: {
		xunfanginfo,
		xunfangtongji


	},
	data() {
		return {
			dialogShow: false,
			dialogShow1: false,
			searchForm: {
				school_id: "",
				class_name: "",
				keyword: "",
				status:"",
				month: new Date().getMonth() + 1

			},
			TargetPmid: 0,
			tableData: [],
			TreeData: [],
			SchoolList: [],
			page: {
				count: 0,
				current_page: 1,
				per_page: 20,
				total_page: 0
			},
			defaultProps: {
				label: 'name',
				value: 'name',
				children: 'children',
				emitPath: false,
				checkStrictly: true,

			},
			xunfang: null,
			year: ''
		}
	},
	mounted() {
		this.year = sessionStorage.getItem("year")
		this.getTargetPmid()
		this.getDataList()
		this.getTree()
		this.getSchoolList()

	},
	methods: {
		getSchoolList() {
			this.$http.post("/api/school_list").then(res => {
				this.SchoolList = res.data
			})
		},
		changeDept() {
			this.getTree()
		},
		getTargetPmid() {
			this.$http.post("/api/sch_qsx_target").then(res => {
				if (res.data && res.data.qsx_pmid) {
					this.TargetPmid = res.data.qsx_pmid
				}
			})

		},
		getTree() {
			this.$http.post("/api/xunfang_grade_tree", { school_id: this.searchForm.school_id }).then(res => {
				this.TreeData = res.data
			})
		},
		getSelectType(e) {
			let node = this.$refs['firstCascader'].getCheckedNodes()
			if (node[0]) {
				let path = node[0].pathNodes[0].label
				if (node[0].pathNodes.length >= 2) {
					this.searchForm.xibu_name = node[0].pathNodes[0].label
					this.searchForm.class_name = node[0].pathNodes[1].label
				} else if (node[0].pathNodes.length >= 1) {
					this.searchForm.xibu_name = node[0].pathNodes[0].label
					this.searchForm.class_name = ""
				} else {
					this.searchForm.xibu_name = ""
					this.searchForm.class_name = ""
				}
			} else {
				this.searchForm.xibu_name = ""
				this.searchForm.class_name = ""

			}

		},
		tongbuData() {
			this.$http.post("/api/tongbu_xunfang", { loading: true, tpmid: this.TargetPmid }).then(res => {
				this.$message.success("同步成功")
				this.page.current_page = 1;
				this.getDataList();
			})
		},
		getDataList() {
			let data = {
				page: this.page.current_page,
				keyword: this.searchForm.keyword,
				xibu_name: this.searchForm.xibu_name,
				class_name: this.searchForm.class_name,
				school_id: this.searchForm.school_id,
				month: this.searchForm.month,
				showloading: true
			}
			this.$http.post("/api/xunfang_group_list", data).then(res => {
				this.tableData = res.data.data
				this.page = res.data.page
			})


		},
		handlePageChange(page) {
			this.page.current_page = page;
			this.getDataList();
		},
		viewInfo(e) {
			// e.keyword = this.searchForm.keyword
			// e.xibu_name = this.searchForm.xibu_name
			// e.class_name = this.searchForm.class_name
			this.xunfang = e

			this.dialogShow = true
		},
		uploadFiles(e) {
			this.$http.post("/api/import_xunfangxls", {
				url: e.src
			}).then(res => {

				this.getDataList()
				this.$message.success("导入成功")
				if (res.data.msg.length > 0) {
					let html = ''
					for (let msg of res.data.msg) {
						html += "<div style='font-size:12px'>" + msg['巡访学生'] + ":" + msg.msg + "</div>"
					}
					this.$alert(html, '未导入数据', {
						dangerouslyUseHTMLString: true
					});
				}
				this.dialogShow1 = false


			})
		},
		exportXls() {
			let data = {
				page: this.page.current_page,
				keyword: this.searchForm.keyword,
				xibu_name: this.searchForm.xibu_name,
				class_name: this.searchForm.class_name,
				school_id: this.searchForm.school_id,
				month: this.searchForm.month,
				showloading: true
			}
			this.$http.post("/api/xunfang_xls_down", data).then(res => {
				if (res.data.url) {
					window.open(res.data.url, "_blank")
					this.$message({
						type: 'success',
						message: '导出成功!'
					});
				}
			})
		}

	}
}
</script>
<style scoped></style>
